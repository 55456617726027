import React from 'react'
import Img from 'gatsby-image'
import { Link, StaticQuery, graphql} from 'gatsby'

export default () => (
	<StaticQuery 
		query={graphql `
			query FooterQuery {
				site{
					siteMetadata{
					  footer{
						companyNav{
						  title
						  elements{
							title
							slug
						  }
						}
						resoucesNav{
						  title
						  elements{
							title
							slug
						  }
						}
						offices{
						  title
						}
						socialNetworks{
						  name
						  icon
						  url
						}
					  }
					}
				  }
				  allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
					edges{
						node{
							title
							slug
						}   
					}
				  }				
			}`

		}	

		render = { data =>{
			const companyNav = data.site.siteMetadata.footer.companyNav
			const servicesNav = data.allContentfulOurServices.edges
			const resoucesNav = data.site.siteMetadata.footer.resoucesNav
			const offices = data.site.siteMetadata.footer.offices
			const socialNetworks = data.site.siteMetadata.footer.socialNetworks
			const year = new Date().getFullYear()
			return(
				<footer className="footer has-background-black">
					<div className="columns">
						<div className="column is-1 is-hidden-mobile">
							<div className="columns" style={{paddingTop:'15px'}}>
								<div className="column is-narrow has-background-danger is-offset-5">
									<div style={{height:'10px'}}></div>
								</div>
							</div>
						</div>
						<div className="column">
							<h1 className="title is-size-4 has-text-white is-capitalized">{companyNav.title}</h1>
							<div className="footer-nav has-padding-bottom">
								<ul>
									{
										companyNav.elements.map((item,index) => {
											return(
												item?(
													<li key={index}>
														<Link className="is-capitalized"
														to={`/${item.slug}`}>
															{item.title}
														</Link>
													</li>):null	
											)
										})
									}
								</ul>
							</div>
						</div>
						<div className="column">
							<h1 className="title is-size-4 has-text-white is-capitalized">services</h1>
							<div className="footer-nav has-padding-bottom">
								<ul>
									{
										servicesNav.map(({node},index) => {
											return(
												<li key={index}>
													<Link className="is-capitalized"
													to={`/services/${node.slug}`}>
														{node.title}
													</Link>
												</li>	
											)
										})
									}
								</ul>
							</div>
						</div>
						<div className="column">
							<h1 className="title is-size-4 has-text-white is-capitalized">{resoucesNav.title}</h1>
							<div className="footer-nav has-padding-bottom">
								<ul>
									{
										resoucesNav.elements.map((item,index) => {
											return(
												<li key={index}>
													<Link className="is-capitalized"
													to={`/${item.slug}`}>
														{item.title}
													</Link>
												</li>	
											)
										})
									}
								</ul>
							</div>
						</div>
						<div className="column">
							<h1 className="title is-size-4 has-text-white is-capitalized">Offices</h1>
							<div className="footer-nav has-padding-bottom has-text-light">
								<ul>
									{
										offices.map((item, index) => {
												return (
													<li key={index}>
														{item.title}
													</li>
												)
										})
									}
								</ul>
							</div>
						</div>
					</div>
					<hr className="has-background-grey"/>
					<div className="container">
						<div className="level">
							<div className="policy level-left">
								<div className="level-item is-capitalized">
									<p>Copyright©{year} Red Digital China</p>
								</div>
								<div className="level-item is-capitalized">
									<Link to='/policy/privacy-policy/'> Privacy Policy</Link>
								</div>
								<div className="level-item is-capitalized">
									<Link to='/policy/cookie-policy/'> Cookie Policy</Link>
								</div>
							</div>
							<div className="level-right">
								{
									socialNetworks.map((item, index)=>{
										return(
											<div className="level-item" key={index}>
												<a className="icon" href={`${item.url}`}>
													<i className={`${item.icon}`}/>
												</a>
											</div>	
										)
									})
								}
							</div>
						</div>
					</div>
				</footer> 
			)	
		}} 
	/>
)