import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Hero from './hero'
import HeroMobile from './hero-mobile'
import Footer from '../components/footer'

class Template extends React.Component {
  render() {
    const { location, children, data } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <div>
       <Hero data={data}/> 
       <HeroMobile data={data}/>
       <div>
         {children}
       </div>
       <Footer/>
      </div>
    )
  }
}

export default Template

