import React from 'react'
import Img from 'gatsby-image'
import Nav from './navigation'

export default ({ data }) => (
    <section className={`hero ${data.hero} ${data.size} is-bold is-hidden-touch`}>
      <div className="hero-head">
        <Nav servicesNav={data.servicesNav}/>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-half">
              <h1 className="title has-text-white has-text-left-desktop
              is-capitalized has-text-centered is-size-1 is-size-3-mobile">
               {data.text}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className={ data.isHidden? "hero-footer is-hidden-desktop" : "hero-footer"}>
        <div className="container">
          <div className="has-text-centered">
            <span className="icon is-large ">
              <a href="#service">
                <i className="fas fa-angle-double-down fa-2x"/>
              </a>
            </span>
            
          </div>
        </div>
         
      </div>
    </section> 
    
)
