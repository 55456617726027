import React from 'react'
import Img from 'gatsby-image'
import Nav from './navigation'

export default ({ data }) => (
    <section className={`hero ${data.hero} is-medium is-bold is-hidden-desktop`}>
      <div className="hero-head">
        <Nav servicesNav={data.servicesNav}/>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-half">
              <h1 className="title has-text-white has-text-left-desktop
              is-capitalized has-text-centered is-size-1 is-size-3-mobile">
                {data.text}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section> 
    
)
